import React from "react";
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next"
import { PersistGate } from "redux-persist/integration/react";
import JsConfig from './js-common/src/js-config';

import App from './App';
import i18n from "./plugins/i18n";
import configureStore from "./store/configureStore";

JsConfig.WEB_API = process.env.REACT_APP_WEB_API;
JsConfig.WEB_SOCKET = process.env.REACT_APP_WEB_SOCKET;
JsConfig.CAPTCHA_KEY = process.env.REACT_APP_RECAPCHA_SITE_KEY;
JsConfig.CAPTCHA_V3 = true;
JsConfig.MUST_LOG = (process.env.REACT_APP_MUST_LOG).toLocaleLowerCase() === 'true';
JsConfig.LOG_API = process.env.REACT_APP_LOG_API;
JsConfig.LOG_ENCODE_KEY = process.env.REACT_APP_LOG_ENCODE_KEY;
JsConfig.IS_NEW_LOG_API = true;

const AppContainer = () => {
  const { store, persistor } = configureStore();

  const { location: { search = '' } = {} } = window;
  const defaultLanguage = navigator.language.split('-')[0];
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route path="/:locale" component={App} />
              <Redirect to={search ? `/${defaultLanguage}${search}` : `/${defaultLanguage}`} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  )
};

render(
  <AppContainer />,
  document.getElementById("root")
);
