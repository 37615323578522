import styled, { css } from 'styled-components';

const Primary = css`
  background-color: ${(props) => props.theme.color.primary};
  border: 1px solid ${(props) => props.theme.color.primary};
  span {
    color: ${(props) => props.product === 'INVESTMEB3' ? props.theme.color.neutral.low.pure : props.theme.color.neutral.high.pure};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.brand.light};
    border-color: ${(props) => props.theme.color.transparent};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.brand.darkest};
    border-color: ${(props) => props.theme.color.brand.darkest};
  }
`;

const Secondary = css`
  background-color: ${(props) => props.theme.color.transparent};
  border: 1px solid ${(props) => props.theme.color.primary};
  span {
    color: ${(props) => props.theme.color.primary};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.primary};
    border-color: ${(props) => props.theme.color.transparent};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.brand.darkest};
    border-color: ${(props) => props.theme.color.brand.darkest};
  }
`;

const Tertiary = css`
  background-color: ${(props) => props.product === 'INVESTMEB3' ? props.theme.color.neutral.low.pure : props.theme.color.neutral.low.lightest};
  border: 1px solid ${(props) => props.product === 'INVESTMEB3' ? props.theme.color.neutral.low.pure : props.theme.color.neutral.low.lightest};
  span {
    color: ${(props) => props.theme.color.neutral.high.pure};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.neutral.low.light};
    border-color: ${(props) => props.theme.color.neutral.low.light};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure} !important;
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.neutral.low.medium};
    border-color: ${(props) => props.theme.color.neutral.low.medium};
  }
`;

const Transparent = css`
  background-color: ${(props) => props.theme.color.transparent};
  border: 1px solid ${(props) => props.theme.color.transparent};
  span {
    color: ${(props) => props.theme.color.neutral.high.pure};
  }
  &:hover, &:active {
    background-color: ${(props) => props.theme.color.transparent};
    border-color: ${(props) => props.theme.color.transparent};
  }
`;

const Dark = css`
  background-color: ${(props) => props.theme.color.background.buttonDark};
  border: 1px solid ${(props) => props.theme.color.background.buttonDark};
  span {
    color: ${(props) => props.theme.color.span};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.background.buttonDarkHover};
    border-color: ${(props) => props.theme.color.background.buttonDarkHover};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.background.buttonDarkHover};
    border-color: ${(props) => props.theme.color.background.buttonDarkHover};
  }
`;

const Darkest = css`
  background-color: ${(props) => props.theme.title === 'b3_dark' ?
  props.theme.color.neutral.low.pure : props.theme.color.neutral.low.darkest};
  border: 1px solid ${(props) => props.theme.title === 'b3_dark' ?
  props.theme.color.neutral.low.pure : props.theme.color.neutral.low.darkest};
  span {
    color: ${(props) => props.theme.title === 'b3_dark' ?
    props.theme.color.neutral.high.pure : props.theme.color.neutral.high.medium};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.neutral.low.darkest};
    border-color: ${(props) => props.theme.color.neutral.low.darkest};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.neutral.low.darkest};
    border-color: ${(props) => props.theme.color.neutral.low.darkest};
  }
`;

const Link = css`
  background-color: ${(props) => props.theme.color.transparent};
  border: 1px solid ${(props) => props.theme.color.neutral.high.pure};
  span {
    color: ${(props) => props.theme.color.neutral.high.pure};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.primary};
    border-color: ${(props) => props.theme.color.transparent};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.color.brand.darkest};
    border-color: ${(props) => props.theme.color.brand.darkest};
  }
`;

const INVESTMEB3 = css`
  border-radius: ${(props) => props.theme.border.radius.rounded};
  &:hover {
    span {
      color: ${(props) => props.theme.color.neutral.low.dark};
    }
  }
`
const variantStyles = (variant = 'primary') => ({
  primary: Primary,
  secondary: Secondary,
  tertiary: Tertiary,
  transparent: Transparent,
  dark: Dark,
  darkest: Darkest,
  link: Link,
}[variant]);

const variantProduct = (product) => ({
  INVESTMEB3: INVESTMEB3
}[product]);

const getButtonSize = (size = 'md') => ({
  xs: {
    height: '32px',
    text: '14px',
  },
  sm: {
    height: '40px',
    text: '14px',
  },
  md: {
    height: '48px',
    text: '14px',
  },
  lg: {
    height: '56px',
    text: '16px',
  }
}[size]);

export const Tooltip = styled.span`
  position: absolute;
  font-size: 12px!important;
  width: calc(100% + 60px);
  top: -50%;
  left: -30px;
  padding: 3px;
  height: 0;
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.neutral.high.dark};
  opacity: 0;
  transition: 0.2s;
`;

export const ButtonContainer = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s all;
  padding: 15px;
  opacity: 1;
  width: ${(props) => props.btnWidth};
  height: ${(props) => getButtonSize(props.btnSize).height}; // LG, MD, SM
  flex-direction: ${(props) => props.iconDirection};
  border-radius: ${(props) => props.theme.border.radius.sm};

  span {
    line-height: 16px;
    font-size: ${(props) => getButtonSize(props.btnSize).text};
    border-bottom: ${(props) => props.isLink && `1px solid transparent`};
  }

  &:hover {
    span {
      border-bottom: ${(props) => props.isLink && `1px solid currentColor`};
    }

    ${Tooltip} {
      opacity: 1;
      height: auto;
    }
  }

  &:active {
    span {
      border-bottom: ${(props) => props.isLink && `1px solid currentColor`};
    }
  }
  &:not([disabled]):active {
    transform: scale(.95);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  ${({ variant }) => variantStyles(variant)};
  ${({ product }) => variantProduct(product)};


  /** Não se importar */
  &.redirect-register-button{
    width: 100%;
    max-width: 328px;
  }

  &.register-button {
    padding: 11px;
    width: max-content;

    span {
      font-size: ${(props) => props.theme.font.size.sm};
      margin: 0;
      width: max-content;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }

  &.cancel-button:hover{
    background-color: ${(props) => props.theme.color.feedback.dangerDark};
    span {
      color: ${(props) => props.theme.color.neutral.high.pure};
    }
  }

  &.fill-hover:hover {
    path {
      fill: ${(props) => props.product === 'INVESTMEB3' ? props.theme.color.neutral.low.pure : props.theme.color.neutral.high.pure};
    }
  }

  &.stroke-hover:hover {
    path {
      stroke: ${(props) => props.product === 'INVESTMEB3' ? props.theme.color.neutral.low.pure : props.theme.color.neutral.high.pure};
    }
  }
  /* ----- */
`;
