const Request = {
    validateSuccess(status) {
      return (status >= 200 && status < 300);
    },

    processResponse(resp) {
      const returnObject = {
        success: false,
        data: null,
        status: 0
      }
      if(resp) {
        returnObject['status'] = resp.status;
        returnObject['success'] = this.validateSuccess(resp.status);
        returnObject['data'] = resp.data;
        if(resp.response) {
          returnObject['data'] = resp.response.data;
          if(!returnObject['status']) {
            returnObject['status'] = resp.response.status;
            returnObject['success'] = this.validateSuccess(resp.response.status);
          }
        }
      }
      return returnObject;
    }
};


export default Request;
