import { useEffect, useCallback } from 'react'

export default function useKeyboardShortcut(callback, config) {
  const targetElement = config.shortcutTarget || document;

  const eventHandler = useCallback((event) => {
    const { code, ctrlKey, altKey, shiftKey } = event;

    if (config.code !== code) return;
    if (config.ctrlKey && !ctrlKey) return;
    if (config.shiftKey && !shiftKey) return;
    if (config.altKey && !altKey) return;

    callback(event);
  },[ callback, config ]);

  useEffect(() => {
    targetElement.addEventListener('keydown', eventHandler);
    return () => targetElement.removeEventListener('keydown', eventHandler);
  }, [ targetElement, eventHandler ]);
}
