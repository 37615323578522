import JsConfig from '../../src/js-config';

const RECAPTCHA_ID = 'grecaptcha-element';

function getElement() {
  let $el = document.getElementById(RECAPTCHA_ID);
  if (!$el) {
    $el = window.document.createElement('div');
    $el.id = RECAPTCHA_ID;
    window.document.body.append($el);
  }
  return $el;
}

function executeV2() {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      reject('grecaptcha is not defined');
      return;
    }

    window.grecaptcha.ready(() => {
      let $el = getElement();

      let isWaiting = true;

      try {
        window.grecaptcha.render($el, {
          sitekey: JsConfig.CAPTCHA_KEY,
          size: 'invisible',
          callback: (response) => {
            isWaiting = false;
            $el.remove();
            resolve(response);
          },
          "expired-callback": (response) => {
            isWaiting = false;
            $el.remove();
            reject(response || 'expired-callback');
          },
          "error-callback": (response, a, b) => {
            isWaiting = false;
            $el.remove();
            reject(response || 'grecaptcha error-callback');
          },
        });
      } catch (e) {
        isWaiting = false;
        $el.remove();
        reject(e);
      }

      const interval = setInterval(() => {
        if (isWaiting) {
          window.grecaptcha.execute();
        } else {
          clearInterval(interval);
        }
      }, 1000);
    });
  });
}

function executeV3(action) {
  return window.grecaptcha.execute(JsConfig.CAPTCHA_KEY, { action })
    .then((token) => token);
}

export default class RecaptchaHelper {
  static load(render) {
    const loadRecaptcha = new Promise((resolve) => {
      const onRecaptchaReady = () => {
        window.grecaptcha.ready(() => {
          resolve();
        });
      };

      if (window.grecaptcha && window.grecaptcha.execute) {
        resolve();
      } else if (window.grecaptcha) {
        onRecaptchaReady();
      }

      let script = document.getElementById('recaptcha-loader');
      if (!script) {
        script = document.createElement('script');
        script.setAttribute('id', 'recaptcha-loader');
        script.defer = true;
        document.head.appendChild(script);
      }

      script.onload = onRecaptchaReady;

      if (render) {
        script.src = `https://www.google.com/recaptcha/api.js?render=${render}`;
      } else {
        script.src = `https://www.google.com/recaptcha/api.js?render=${JsConfig.CAPTCHA_KEY}`;
      }
    });

    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject();
      }, 15000);
    })

    return Promise.race([loadRecaptcha, timeout]);
  }

  static async execute(action = 'submit') {
    if (JsConfig.CAPTCHA_V3) {
      await this.load();
      return executeV3(action);
    } else {
      await this.load('explicit');
      return executeV2();
    }
  }
}
