
import Request from "../helpers/RequestHelper";
import RequestHandler from "../handlers/RequestHandler";

const _api = "educational/";
const _courses = "courses/";
const _search = "search/"

export const search = {
  async courses({ strName }) {
    try {
      const resp = await Request.get(`${_api}${_courses}${_search}`, false,{perPage: 500, page: 1, query: strName, expand: "activeClass"});
      return RequestHandler.processResponse(resp);
    } catch (error) {
      return RequestHandler.processResponse(error);
    }
  }
}
