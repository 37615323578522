import React, { useState, useEffect, useContext } from 'react'
import ReactDom from 'react-dom';
import { ModalOverlay, ModalWrapper, DivModal, ModalHeader, ModalBody, ModalFooter, ModalError, CloseIcon } from "./styles";
import { ThemeContext } from "styled-components";
import Svg from "../../../handlers/HandleSvg";

const Modal = ({ isShowing, toggle, children, width, height, styleModal, center = false, className = "", canBeClosed = true, hideHeader = false, fullScreenMobile = true }) => {
    useEffect(() => {
      const listener = function (e) {
        if (canBeClosed && (e.key === 'Escape' || e.key === 'Esc')) {
          e.preventDefault();
          e.stopPropagation();

          isShowing && toggle();
        }
      }

      window.addEventListener('keyup', listener)

      return (() => {
        window.removeEventListener('keyup', listener)
      })

    }, [isShowing, toggle])

    return isShowing
      ? ReactDom.createPortal(
          <ModalOverlay hideHeader={hideHeader} className="modal-open">
            <ModalWrapper center={center} className={className}>
              <DivModal fullScreenMobile={fullScreenMobile} width={width} height={height} style={styleModal} className={className}>
                {children}
              </DivModal>
            </ModalWrapper>
          </ModalOverlay>,
          document.body
        )
      : null;
  }


  export const ModalHeaderClass = ({ toggle, children, errorText, errorType, canBeClosed = true, className = "", containerStyle = {}, onClick = () => {} }) => {
    const themeContext = useContext(ThemeContext);
    const [ closeModal, setCloseModal ] = useState(false);
    let errorMsg = errorText;

    useEffect(() => {
      setCloseModal(!errorMsg);
    }, [errorMsg, errorText]);

    const types = [
      {
        type: "error",
        color: themeContext.color.feedback.dangerDark,
        icon: "alert-error-ic",
      },
      {
        type: "alert",
        color: themeContext.color.feedback.warning,
        icon: "alert-error-ic",
      },
    ];

    const funcCloseModal = () => {
      errorMsg = '';
      setCloseModal(true);
    }

    const type =  types.find(x => x.type === errorType);

    return (
      <ModalHeader className={`header ${className}`} style={containerStyle}>
        {children}
        {!closeModal && (
          <ModalError errorColor={type ? type.color : ""}>
            {type && (
              <Svg
                name={type.icon}
                width="16px"
                height="16px"
                color={type.type === "error" ? themeContext.color.neutral.high.pure : themeContext.color.neutral.low.pure}
              />
            )}
            <p style={{ color: type && type.type === "error" && themeContext.color.neutral.high.pure }}>{errorText}</p>
            {canBeClosed ? (
              <CloseIcon onClick={() => funcCloseModal()}>
                <Svg
                  name="ic-close"
                  width="24px"
                  height="24px"
                  color={type && type.type === "error" ? themeContext.color.neutral.high.pure : themeContext.color.neutral.low.pure} />
              </CloseIcon>
            ) : null}
          </ModalError>
        )}
        {canBeClosed ? (
          <CloseIcon onClick={() => {
            onClick();
            toggle();
          }}>
            <Svg
              name="ic-close"
              width="24px"
              height="24px"
              color={themeContext.isWL ?  themeContext.color.neutral.low.pure : null}/>
          </CloseIcon>
        ) : null}
      </ModalHeader>
    );
  }

  export const ModalBodyClass = ({ children, className = "", style = {} }) => (
      <ModalBody className={className} style={style}>
          {children}
      </ModalBody>
  )

  export const ModalFooterClass = ({ children, className = "", style = {} }) => (
    <ModalFooter className={className} style={style}>
        {children}
    </ModalFooter>
  )

  export const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggle,
    }
  }

export default Modal;
