import React from 'react';
import Modal, { ModalHeaderClass } from '../Modal';
import { Title, Body } from './styles';
import { useTranslation } from 'react-i18next';
import Login from '../../Login';
import { useResponsive } from '../../../helpers/HooksHelper';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const LoginModal = ({
  title,
  canBeClosed = false,
  toggle = () => {},
  fullScreenMobile = false,
  isShowing,
}) => {
  const { t } = useTranslation('translations');
  const { isMobile } = useResponsive();
  const themeContext = useContext(ThemeContext);
  const mobileBkgColor = themeContext.color.neutral.low.dark;
  return (
    <Modal
      fullScreenMobile={fullScreenMobile}
      canBeClosed={canBeClosed}
      styleModal={{
        padding: '24px',
        width: "100%",
        maxHeight: "600px",
        background: isMobile || themeContext.isWL ? mobileBkgColor : '',
        color: themeContext.isWL ? themeContext.color.neutral.low.pure : '',
      }}
      width="512px"
      center
      {...{ isShowing, toggle }}>
      <ModalHeaderClass
          canBeClosed={canBeClosed}
          containerStyle={{
            justifyContent: "space-between",
            flexDirection: title ? "row" : "row-reverse",
            paddingLeft: '0'
          }}
          toggle={toggle}
        >
          <Title>{t(title)}</Title>
      </ModalHeaderClass>
      <Body>
        <Login isModalLogin={true} isExpiredSession={true} actionToRegister={toggle}/>
      </Body>
    </Modal>
  )
}
