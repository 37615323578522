import INVESTACADEMY_DARK from "./dark";
import INVESTACADEMY_LIGHT from "./light";
import INVESTMEB3_DARK from "./b3";
import INVESTMEB3_NEWB3 from "./b3_dark";
import INTEREDUCA_LIGHT from "./inter";
import GENIAL_LIGHT from "./genial";
import PEERBR_LIGHT from "./peerbr";
import StorageHelper from "../../helpers/StorageHelper";

const themes = {
  INVESTACADEMY_DARK,
  INVESTACADEMY_LIGHT,
  INVESTMEB3_DARK,
  INVESTMEB3_NEWB3,
  INTEREDUCA_LIGHT,
  GENIAL_LIGHT,
  PEERBR_LIGHT,
};

export const product = (process.env.REACT_APP_PRODUCT && process.env.REACT_APP_PRODUCT.toUpperCase()) || "INVESTACADEMY";
export const envTheme = `${product}_${process.env.REACT_APP_THEME || 'DARK'}`;

StorageHelper.setToLocalStorage("envTheme", envTheme);
StorageHelper.setToLocalStorage("envProduct", product);

export const getTheme = (paramTheme = null) => {
  let theme;
  if (paramTheme) {
    const themeToFind = `${product}_${paramTheme}`.toUpperCase();
    theme = Object.entries(themes).find((el) => {
      return el[0].toUpperCase() === themeToFind;
    });
  }
  
  if (!theme) {
    theme = Object.entries(themes).find((el) => {
      return el[0].toUpperCase() === envTheme.toUpperCase()
    });
  }
  return theme ? theme[1] : INVESTACADEMY_DARK;
};

export const getNameByProduct = (showIA = false) => {
  const theme = getTheme(envTheme);
  return theme.name === 'Nelogica' && showIA ? 'Invest Academy' : theme.name;
}

export const getProductUrl = () => {
  const url = window.location.hostname.replace(/^www\./, '');
  return url === 'localhost' ? 'invest.academy' : url;
}

export const getAboutUsByProduct = () => {
  const theme = getTheme(envTheme);
  return theme.aboutUs;
}

export const getFonts = () => {
  return Object.values(themes).map((el) => el.font.families);
}
