import React, { useContext, useEffect, useState } from "react";
import { ButtonContainer, Tooltip } from "./styles";
import SvgIcon from '../../../handlers/HandleSvg';
import ErrorBoundary from '../../../handlers/ErrorBoundary';
import classnames from 'classnames';
import { ThemeContext } from "styled-components";
import { product } from "../../../styles/theme/themes";

export const Button = ({
  leftIcon = false,
  icon = null,
  iconWidth = "24px",
  iconHeight = "24px",
  iconColor,
  iconStyle = {},
  action,
  width = "100%",
  variant = "primary",
  styleButton = {},
  styleText = {},
  className,
  disabled = false,
  disabledTooltip,
  loading = false,
  children,
  size = 'md',
  isLink = false,
  btnType = "button",
}) => {
  const [_disabled, setDisabled] = useState(disabled);
  const themeContext = useContext(ThemeContext);
  const colorTextDark = themeContext.color.neutral.low.dark;
  const colorTextHighPure = themeContext.color.neutral.high.pure;

  const getColorByProduct = () => {
    if (variant === "secondary") {
      return themeContext.color.primary;
    }
    if (variant === "darkest" || variant === "dark") {
      return themeContext.color.buttonDarkIcon;
    }
    switch (product) {
      case "INVESTMEB3":
        return colorTextDark;
      default:
        return colorTextHighPure;
    }
  }

  useEffect(() => {
    if (loading) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [loading]);

  return (
    <ErrorBoundary>
      <ButtonContainer
        product={product}
        style={styleButton}
        iconDirection={leftIcon ? "row-reverse" : "row"}
        btnWidth={width}
        onClick={action}
        variant={variant}
        className={classnames(className)}
        disabled={_disabled ? _disabled : disabled}
        btnSize={size}
        isLink={isLink}
        type={btnType}
      >
        {!loading && <span style={{margin: icon ? '0 8px' : '0', ...styleText}}>{children}</span>}
        {(disabled && disabledTooltip) && <Tooltip>{disabledTooltip}</Tooltip>}
        <SvgIcon
          style={iconStyle}
          color={iconColor || getColorByProduct()}
          name={loading ? 'loading' : icon}
          width={iconWidth}
          height={iconHeight}
        />
      </ButtonContainer>
    </ErrorBoundary>
  );
};
