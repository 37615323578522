import Request from "../helpers/RequestHelper";
import IpLocationRoutes from '../json/IpLocationRoutes.json';
import CountryHelper from "@common/classes/helpers/CountryHelper";

export const locationApi = {
  detectCountry() {
    const env = window.location.href.includes('localhost') ? 'development' : 'production';

    return Request.post(IpLocationRoutes[env])
      .then(response => {
        const country = response.data.country || CountryHelper.getCountryCode(CountryHelper.BRAZIL_ID);
        const nCountryId = CountryHelper.getCountryByCode(country)?.id;

        return {
          ...response.data,
          country,
          nCountryId,
        }
      })
      .catch(() => {
        return {
          country: CountryHelper.getCountryCode(CountryHelper.BRAZIL_ID),
          nCountryId: CountryHelper.BRAZIL_ID,
          ip: null,
        };
      });
  },
};

export default locationApi;
