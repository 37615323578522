const DateHelper = {
  getUSDateTimeNow() {
    const date = new Date();
    const [dd, mm, yyyy] = date.toLocaleDateString("pt-BR", { timeZone: "America/Sao_Paulo" }).split("/");
    const hour = date.toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" });
    return `${yyyy}-${mm}-${dd} ${hour}.000`;
  },
  getObjDateNow() {
    const today = new Date();
    const [dd, mm, yyyy] = today.toLocaleDateString().split("/");
    return { day: parseInt(dd), mounth: parseInt(mm), year: parseInt(yyyy) };
  },
  getObjCustomDate(string, separator = "/", locale = "pt-BR") {
    const [dd, mm, yyyy] = this.getDateByLocale(string, separator, locale);
    return { day: parseInt(dd), mounth: parseInt(mm), year: parseInt(yyyy) };
  },
  getBrFormattedDate(string = undefined, separator = "/") {
    const regex = /(\d{2}:)(\d{2}:)(\d{2})/gm;
    const date = this.getObjCustomDate(string.replace(regex, ""), separator, "en-US");
    return `${date.day.toString().padStart(2, "0")}/${date.mounth.toString().padStart(2, "0")}/${date.year}`;
  },
  getUsFormattedDate(string) {
    const regex = /(\d{2}:)(\d{2}:)(\d{2})/gm;
    const date = this.getObjCustomDate(string.replace(regex, ""), "/", "pt-BR");
    return `${date.year}-${date.mounth.toString().padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;
  },
  getDateByLocale(string, separator = "/", locale = "pt-BR") {
    let yyyy, mm, dd;
    if (locale !== "pt-BR") {
      [yyyy, mm, dd] = string.split(separator);
    } else {
      [dd, mm, yyyy] = string.split(separator);
    }
    return [dd, mm, yyyy];
  },
  getDateDay(date) {
    let day;
    if (date) {
      const objDate = typeof date === 'object' ? date : new Date(this.fixDateStringSafari(date));
      if (!isNaN(objDate)) day = objDate.getDay();
    }
    return day;
  },
  getDateFromTimezone(timezone) {
    return new Date().toLocaleString("en-US", {timeZone: timezone});
  },
  createDate(dateTime) {
    const aux = new Date(dateTime ? this.fixDateStringSafari(dateTime) : -8640000000000000);
    return aux;
  },
  isIntervalTime(dtInicio, dtFinal) {
    const now = new Date();
    let result = false;
    const dateInicio = this.createDate(dtInicio);
    const dateFinal = this.createDate(dtFinal);
    if(!dtInicio && !dtFinal) {
      result = true;
    } else if (!dtInicio) {
      result = now <= dateFinal;
    } else if (!dtFinal) {
      result = now >= dateInicio;
   } else {
      result = now >= dateInicio && now <= dateFinal;
   }
    return result;
  },
  verifyIsSoon(courseData) {
    const now = new Date();

    if (!courseData) return true;

    const waitingStart = this.createDate(courseData.dtStartSubscription);
    const subscriptionStart = this.createDate(courseData.dtStartPlan);

    return (waitingStart && (now < waitingStart) || (!waitingStart && (now < subscriptionStart)));
  },
  getDateInfo(date) {
    const dateJs = new Date(this.fixDateStringSafari(date));

    let day = dateJs.getDate() || 0; //1-31
    day = day < 10 ? `0${day}` : day; //01-31

    let month = dateJs.getMonth() + 1 || 0; //1-12
    month = month < 10 ? `0${month}` : month; //01-12

    let year = dateJs.getFullYear() || 0; //YYYY
    let hour = dateJs.getHours() || 0; //0-23
    let minute = dateJs.getMinutes() || 0; //0-59
    minute = minute < 10 ? `0${minute}` : minute; //00-59

    let formattedDate = date && dateJs && new Intl.DateTimeFormat('pt-BR').format(dateJs);

    return {
      day,
      month,
      year,
      hour,
      minute,
      formattedDate
    };
  },
  removeTimezone(date) {
    return new Date(date.getTime() + date.getTimezoneOffset());
  },
  fixDateStringSafari(dateStr) {
    if (dateStr)
      return dateStr.split(".")[0].replace(/-/g, '/');

    return "";
  },
  years(value) {
    const years = [];
    const today = new Date();

    years.push({
      value: today.getFullYear(),
      text: today.getFullYear().toString(),
    });

    for (let i = 0; i < value - 1; i++) {
      today.setFullYear(today.getFullYear() + 1);
      years.push({
        value: today.getFullYear(),
        text: today.getFullYear().toString(),
      });
    }
    return years;
  },
  getMonths() {
    const months = [
      {
        value: 1,
        text: "monthList.jan",
      },
      {
        value: 2,
        text: "monthList.feb",
      },
      {
        value: 3,
        text: "monthList.mar",
      },
      {
        value: 4,
        text: "monthList.apr",
      },
      {
        value: 5,
        text: "monthList.may",
      },
      {
        value: 6,
        text: "monthList.june",
      },
      {
        value: 7,
        text: "monthList.july",
      },
      {
        value: 8,
        text: "monthList.aug",
      },
      {
        value: 9,
        text: "monthList.sept",
      },
      {
        value: 10,
        text: "monthList.oct",
      },
      {
        value: 11,
        text: "monthList.nov",
      },
      {
        value: 12,
        text: "monthList.dec",
      }
    ];
    return months;
  }
};

export default DateHelper;
