const NON_DIGIT_PATTERN = /[^\d]+/g;

export const isUniform = (str) => {
  const pivot = str[0];
  let i = 1;

  while (i < str.length) {
    if (pivot !== str[i]) return false;
    i += 1;
  }

  return true;
};
export const validateCpf = (value) => {
  if (value) {
    const strCPF = value.replace(NON_DIGIT_PATTERN, "");
    let sum = 0;
    let rest = 0;
    let i = 1;

    if (isUniform(strCPF)) return false;

    while (i <= 9) {
      sum += parseInt(strCPF.charAt(i - 1), 10) * (11 - i);
      i += 1;
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== parseInt(strCPF.charAt(9), 10)) return false;

    sum = 0;
    i = 1;
    while (i <= 10) {
      sum += parseInt(strCPF.charAt(i - 1), 10) * (12 - i);
      i += 1;
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== parseInt(strCPF.charAt(10), 10)) return false;

    return true;
  }
  return false;
};
export const validateCnpj = (value) => {
  if(value) {
    const strCNPJ = value.replace(NON_DIGIT_PATTERN, "");

    if (isUniform(strCNPJ)) return false;

    let length = strCNPJ.length - 2;
    let num = strCNPJ.substring(0, length);
    const digits = strCNPJ.substring(length);
    let sum = 0;
    let pos = length - 7;
    let i = 0;

    while (i < length) {
      sum += parseInt(num.charAt(i), 10) * pos;
      if (pos <= 2) pos = 10;

      i += 1;
      pos -= 1;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0), 10)) return false;

    length += 1;
    num = strCNPJ.substring(0, length);
    sum = 0;
    pos = length - 7;
    i = 0;

    while (i < length) {
      sum += parseInt(num.charAt(i), 10) * pos;
      if (pos <= 2) pos = 10;

      i += 1;
      pos -= 1;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1), 10)) return false;

    return true;
  }
  return false;
};
export const validatePassword = (value, type) => {
  const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
  // eslint-disable-next-line no-useless-escape
  const specialCharactersRegex = /[_+\-=\[\]{};\\/\|]/;
  const number = /[0-9]/;
  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;

  switch (type) {
    case "specialChar":
      if (value.search(specialChar) === -1) return false;
      return true;
    case "min":
      if (value.length < 8) return false;
      return true;
    case "hasNumber":
      if (value.search(number) === -1) return false;
      return true;
    case "upperCase":
      if (value.search(upperCase) === -1) return false;
      return true;
    case "lowerCase":
      if (value.search(lowerCase) === -1) return false;
      return true;
    case "cantHaveSpace":
      return value.length > 0 && value.includes(" ");
    case "prohibitedChar":
      return (value.length > 0 && specialCharactersRegex.test(value));
    default:
      return false;
  }
};

export default validatePassword;
