import itemDev from "./dev/lpCourse";
import itemProd from "./prod/lpCourse";
import itemB3 from "./b3/lpCourse";

var course;

if (process.env.REACT_APP_PRODUCT==='investmeb3') {
  course = itemB3;
} else {
  course = process.env.REACT_APP_ENVIRONMENT === 'development' ? itemDev : itemProd;
}
export default course;
