import styled from "styled-components";

export const Container = styled.aside`
  position: relative;
  width: 100%;
  ul {
    display: flex;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    ul {
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    border-bottom: 1px solid ${props => props.theme.color.navBorder};
  }
  &.invest-me-nav {
    padding-top: 20px;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    nav {
      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding-left: 16px;
      }
      ul {
        justify-content: flex-start;
      }
    }
  }
`;

export const NavItem = styled.li`
  list-style: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => {
    if (props.theme.var.navItem) {
      return "width: initial";
    } else {
      return "width: 100%;";
    }
  }}
  &:first-child {
    padding-left: 0px;
  }

  .active {
    color: ${(props) => props.theme.color.navColor};
    font-weight: ${(props) => props.theme.font.weight.bold};

    .nav-link {
      path {
        /* fill: ${(props) => props.theme.color.neutral.high.lightest}; */
        /* stroke: ${(props) => props.theme.color.neutral.high.lightest}; */
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    .active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 3px;
        left: 0;
        bottom: -2px;
        width: 100%;
        background: ${(props) => props.theme.color.background.nav};
      }
    }
  }
  div {
    display: none;
  }
  a {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    ${(props) => {
      if (props.theme.var.navItem) {
        return "padding: 10px 28px 4px;"
      } else {
        return "width: 100%;";
      }
    }}
    height: 38px;
    /* color: ${(props) => props.theme.color.secondaryText}; */
    text-decoration: none;
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.navLink};
    &:hover {
      color: ${(props) => props.theme.color.navColor};
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: ${(props) => props.theme.font.size.xs};
      span {
        text-align: center;
        display: inline-block;
        margin-top: 8px;
      }
      ${(props) => {
        if (props.product === "INVESTMEB3") {
          return `&::before {
            content: "";
            position: absolute;
            height: 1px;
            left: 0;
            width: 100%;
            bottom: -15px;
            background: ${props.theme.color.neutral.low.light};
          }`
        }
      }}
    }

    &:last-child {
    }
    div {
      display: block;
    }

    .active {
      position: relative;
      span {
        color: ${(props) => props.color};
      }
      &::before {
        content: "";
        position: absolute;
        height: 3px;
        left: 0;
        ${(props) => {
          if (props.theme.var.navItem) {
            return "top: -9px;";
          } 
          return "bottom: -15px;";
        }}
        width: 100%;
        background: ${(props) => props.theme.isWL ? props.theme.color.primary : props.color};
      }
    }
  }
`;

export const NavLinkInvestMe = styled.div`
  list-style: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  &:focus-visible {
    outline: none;
  }
  &:last-child {
    margin-right: initial;
  }
  a {
    transition: all 25ms linear;
    &:focus-visible {
      outline: none;
    }
    width: fit-content;
    border-radius: 100px;
    border: 1px solid;
    border-color:${(props) => props.theme.color.neutral.low.medium};
    padding: 6px 16px;
    text-decoration: none;
    color: ${(props) => props.theme.color.neutral.high.light};
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: ${(props) => props.theme.font.size.xs};
    line-height: 16px;
  }
  .active {
    ${(props) => {
      if (props.theme.title === 'b3_dark') {
        return `
        border-color: ${props.theme.color.neutral.high.pure};
        background-color: ${props.theme.color.neutral.high.pure};
        color: ${props.theme.color.neutral.low.pure};
        `;
      } else {
        return `
        border-color: ${props.theme.color.primary};
        background-color: ${props.theme.color.primary};
        color: ${props.theme.color.neutral.low.pure};
        `;
      }
    }}
  }
`;
