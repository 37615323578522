export default class LanguageHelper {
  static LANGUAGES = [
    {
      id: 0, // (sim, é zero)
      code: 'pt-BR',
      i18nCode: 'pt',
    },
    {
      id: 1,
      code: 'en-US',
      i18nCode: 'en',
    },
    {
      id: 2,
      code: 'es-ES',
      i18nCode: 'es',
    },
  ];

  static LANGUAGES_MAP = {
    'pt-BR': 0,
    'pt-br': 0,
    pt: 0,
    'en-US': 1,
    'en-us': 1,
    en: 1,
    'es-ES': 2,
    'es-es': 2,
    es: 2,
  };

  static getLanguageByCode(code) {
    const strCode = code.toLocaleLowerCase();
    const id = this.LANGUAGES_MAP[strCode];
    return this.getLanguageById(id);
  }

  static getLanguageById(id) {
    const langId = parseInt(id, 10);
    return this.LANGUAGES.find((lang) => lang.id === langId);
  }

  static getDefaultAppLanguage(callbackLang) {
    const lang = window.navigator.language || callbackLang;
    return this.getLanguageByCode(lang);
  }

  static getUrlLanguageCode(strLangPropName = 'lang') {
    const params = new URLSearchParams(document.location.search);
    return params.get(strLangPropName) || '';
  }

  static getUrlLanguage() {
    const urlLang = this.getUrlLanguageCode();
    let urlCode = '';
    if (urlLang) urlCode = this.getLanguageByCode(urlLang);
    return urlCode;
  }
}
