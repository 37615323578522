import React, { useContext } from "react";
import { Container } from "./styles";
import { ThemeContext } from "styled-components";
import loadable from "@loadable/component";

const Svg = ({
  width = '48px',
  height = '48px',
  name,
  color = 'currentColor',
  style = {},
  containerStyle = {},
  iconClass = "",
  onClick = () => {}
}) => {
  const themeContext = useContext(ThemeContext);
  const _color = color || themeContext.color.neutral.high.pure;
  let SvgRender = null;
  if (name) {
    SvgRender = loadable(() => import(`./svg/${name}.jsx`));
  }
   return (
     <Container className={iconClass} onClick={onClick} style={containerStyle}>
        {name && <SvgRender width={width} height={height} style={style} _color={_color}/>}
     </Container>
   );
}

export default Svg;
