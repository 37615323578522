import { product } from "../styles/theme/themes";
import lpCourse from "../data/pt/lpCourse";

const prdUris = ['https://beta-playlist.invest.academy', 'https://playlist-discovery-b3.nelogica.com.br', 'https://playlist-discovery-b3.hub3.app.br', 'http://localhost:8080'];
const devUris = ['https://beta-playlist.invest.academy', 'https://beta-playlist.invest.academy', 'http://localhost:8080'];
const isPrd = process.env.REACT_APP_ENVIRONMENT === 'production';

const EnvHelper = {
  isPrd() {
    return isPrd
  },
  envId() {
    return process.env.REACT_APP_WL_ENVIRONMENT_ID ?? null;
  },
  isInvestMe() {
    return product === "INVESTMEB3";
  },
  isInterInvest() {
    return product === "INTEREDUCA";
  },
  isGenial() {
    return product === "GENIAL";
  },
  isPeer() {
    return product === "PEERBR";
  },
  isWL() {
    return (product === "INTEREDUCA" || product === "GENIAL" || product === "PEERBR");
  },
  MBA_ID() {
    return 84;
  },
  acceptedUri() {
    return isPrd ? prdUris : devUris
  },

  browserDetect() {
    let userAgent = navigator.userAgent;
    const brands = navigator.userAgentData && navigator.userAgentData.brands;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    return {
      name: browserName,
      versions: brands,
    };
  },

  getBkgColor(nClassID, noDefault = false, nCourseID = -1) {
    const defaultBkg = noDefault ? null : ["#024585", "#2B7EB1"];
    if (nClassID) {
      let resp = lpCourse.filter((el) => el.classID === nClassID);
      if (resp.length > 0) {
        return resp[0].bkgColor;
      } else {
        resp = lpCourse.filter((el) => el.courseID === nCourseID);
        if (resp.length > 0) return resp[0].bkgColor;
        return defaultBkg;
      }
    } else {
      return defaultBkg;
    }
  },
}

export default EnvHelper
