const peerbr = {
  title: "PeerBr",
  isWL: true,
  isPeerBr: true,
  director: "Ana Paula Souza Tralback",
  name: "PeerBr Academy",

  var: {
    showInHeader: true,
    navIcon: false,
    navItem: true,
    directorSignature: {
      top: '-40px',
      left: '15px',
      width: '120px',
    },
    homeTabletMaxWidth: '800px'
  },

  homeInformation: {
    cardOne: 'homeInformation.peerbr.cardOne',
    cardTwo: 'homeInformation.peerbr.cardTwo',
    cardThree: 'homeInformation.peerbr.cardThree',
  },

  login: {
    motivacional: 'login.motivacionalPeerBR',
  },

  register: {
    message: 'register.messagePeerBr',
  },

  header: {
    blogLink: 'https://blog.peerbr.com/',
    blogName: 'Blog',
  },

  aboutUs: {
    notShowAboutUs: false,
    title: 'titlePeerBr',
    subtitle: 'subtitlePeerBr',
  },

  knowTeam: {
    title: 'knowTeam.peerbr.title',
    subtitle: 'knowTeam.peerbr.subtitle',
  },

  imgs: {
    homeVideoBg: '/themes/intereduca/info_video_home.svg',
    homeVideoThumb: 'https://i.vimeocdn.com/video/1480646872-b7e5e57a93f58e1c67298a50a1c6019b76519feca505779f2896e5bfeff1124e-d?mw=1000&mh=563',
    homeVideo: 'https://player.vimeo.com/video/731934018?h=8df42a303a&amp;badge=0&amp;autoplay=1;autopause=0&amp;player_id=0&amp;app_id=58479',
    unavailableContent: null,
    unavailableContentMobile: null,
    register: null,
    defaultPerfil: "/themes/intereduca/default-perfil.png",
    directorSignature: '/themes/peerbr/director-signature.png',
    directorSignatureBlue: '/themes/peerbr/director-signature-blue.png',
    homeTablet: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/home-peerbr.png',
  },

  breakpoints: {
    xs: "376px",
    sm: "426px",
    md: "769px",
    mdx: "900px",
    lg: "1025px",
    xl: "1440px",
    xxl: "1921px",
  },

  box: {
    md: "780px",
    lg: "1366px",
  },

  font: {
    family: {
      site: "Roboto",
      title: "Roboto",
    },
    families: "Roboto:ital,300,400,500,600,700",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    size: {
      xxxs: "10px",
      xxs: "12px",
      xs: "14px",
      sm: "16px",
      md: "20px",
      lg: "24px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    custom: {
      size: {
        custom01: "18px"
      }
    }
  },

  border: {
    radius: {
      none: "0px",
      sm: "4px",
      md: "8px",
      lg: "20px",
      rounded: "100px",
      circular: "50%",
    },
  },

  color: {
    brand: {
      pure: "#5820cc",
      darkest: "rgba(53, 15, 114, 0.75)",
      light: "rgba(53, 15, 114, 0.75)",
    },
    gradient: {
      aboutUs: "linear-gradient(85.03deg, #5820cc 2.15%, #350F72 95.3%)",
      banner: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 63.54%, #020c13 100%)',
      bannerMobile: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 48.44%, #020c13 100%)',
    },
    neutral: {
      low: {
        pure: "#5820cc",
        darkest: "#F5F5F5",
        dark: "#F5F5F5",
        medium: "#5F6167",
        light: "#162D3B",
        lightest: "#1C384A",
      },
      high: {
        pure: "#FFFFFF",
        lightest: "#F5F4F2",
        light: "#D4DADD",
        medium: "#99A5AC",
        dark: "#00d8c9",
        darkest: "#45545C",
      },
    },
    feedback: {
      success: "#009e22",
      warning: "#FEE60B",
      danger: "#F75158",
      dangerLight: "#FF7379",
      dangerDark: "#AB1A31",
      message: "#99A5AC",
    },
    background: {
      body: "#FFFFFF",
      imageInfoSection: "#FFFFFF",
      loader: "#FFFFFF",
      unavailableContent: "#FFFFFF",
      infoCards: "#FFFFFF",
      card: "#F5F5F5",
      checkout: "#F5F5F5",
      courseCard: "#F5F5F5",
      cardDivider: "#5F6167",
      input: "#FFFFFF",
      inputSearch: "#F5F5F5",
      result: "#F5F5F5",
      resultHover: "#E4E4E4",
      tab: "#FFFFFF",
      subMenu: "#FFFFFF",
      modal: "#FFFFFF",
      footer: "#F5F5F5",
      header: "#FFFFFF",
      headerItem: "#F5F5F5",
      infoBar: "#F5F5F5",
      knowTeam: "#FFFFFF",
      bottomBar: "#F5F5F5",
      cardImage: "#FFFFFF",
      nav: "#5820cc",
      textArea: "#F5F5F5",
      homeIndicator: "#5F6167",
      menu: "#FFFFFF",
      progressBar: "#FFFFFF",
      textInputDisabled: "#FFFFFF",
      textInput: "#FFFFFF",
      banner: '#020c13',
      courseLP: '#F5F5F5',
      parallaxCourseLP: '#FFFFFF',
      compareBlock: "#FFFFFF",
      subscriptionContent: "#5820cc",
      inputSearchContainer: "#F5F5F5",
      inputSearchShortcut: "#D4D4D4",
      progressColor: "#FFFFFF",
      collapsible: "#FFFFFF",
      moduleIndex: "#5820cc",
      moduleIndexOpen: "#FFFFFF",
      buttonDark: "#D4DADD",
      buttonDarkHover: "#99A5AC",
    },
    mobile: {
      background: "#F5F5F5",
      title: "#000000",
      subtitle: "#5F6167",
      header: "#FFFFFF",
      couseModuleHeader: "#162D3B",
      card: "#FFFFFF",
      collapsible: "#5F6167",
      homeButton: "#5F6167",
      message: "#5F6167",
      inputSearch: "#F5F5F5",
    },
    // CUSTOM
    backgroundDegrade: "linear-gradient(to bottom, rgba(73, 73, 73, 0), rgba(31, 31, 31, 0.5) 110%)",
    transparent: "transparent",
    primary: "#5820cc",
    secondary: "#5F6167",
    title: "#5820cc",
    subtitle: "#5F6167",
    moduleIndex: "#5F707B",
    subtitleCustom: "#a6b2ba",
    description: "#5F707B",
    price: "#5F6167",
    label: "#5F6167",
    text: "#5F6167",
    textCustom: "#5F6167",
    secondaryText: "#909ba2",
    home: "#000000",
    header: "#000000",
    textLight: "#5F6167",
    cardHeader: "#000000",
    cardPrice: "#5F6167",
    cardText: "#5F6167",
    cardTextCheckout: "#5F6167",
    stepNumber: "#FFFFFF",
    infoLineTitle: "#5F6167",
    border: "#F5F5F5",
    tabBorder: "#F5F5F5",
    navBorder: "#F5F5F5",
    headerBorder: "#F5F5F5",
    collapsibleBorder: 'transparent',
    textAreaBorder: "#F5F5F5",
    menuBorder: "#000000",
    cardBorder: "#000000",
    textInputBorder: "#5820cc",
    quiz: "#5820cc",
    quizDarkest: "#5820cc",
    tabColor: "#5820cc",
    navColor: "#5820cc",
    tabBar: "#5820cc",
    darkSpan: "#5F6167",
    loginTitle: "#000000",
    message: "#5F6167",
    messageCustom: "#a6b2ba",
    formTitle: "#5F6167",
    navLink: "#5F6167",
    courseCard: "#5F6167",
    errorModal: "#5F6167",
    span: "#5820cc",
    stroke: "#a6b2ba",
    strokeLight: "#45545C",
    collapsible: "#5F6167",
    comment: "#5F6167",
    unavailableContent: "#5F6167",
    courseProgress: "#5F6167",
    cardInfoCalendar: "#5F6167",
    courseLP: "#000000",
    infoSection: "#000000",
    continueText: "#5F6167",
    filterOptions: "#5F6167",
    classIC: "#45545C",
    headerTable: "#a6b2ba",
    progressColor: "#5820cc",
    progressText: "#000000",
    liveNow: "#FF7379",
    iconColor: "#5F6167",
    searchIconColor: "#5F6167",
    inputSearchShortcutColor: "#5F6167",
    inputColor: "#000000",
    placeHolderColor: "#5F6167",
    titleContainerText: "#5F6167",
    copyrightText: "#5F6167",
    iconColorMobile: "#5820cc",
    iconColorMobileCustom: "#FFFFFF",
    userHome: "#5820cc",
    nav: "#5820cc",
    breadcrumbLabel:"#45545C",
    buttonDarkIcon: "#5820cc",
    titleAboutUs: "#FFFFFF",
    ribbonBackground: "#F5A42D",
    ribbonText: "#38276F",
  },
};

export default peerbr;
