import Request from "../helpers/RequestHelper";
import RequestHandler from "../handlers/RequestHandler";
import EnvHelper from "../helpers/EnvHelper";

const _api = EnvHelper.isInvestMe() && !EnvHelper.isPrd() ? "educational-b3/" : "educational/";
const _notifications = "notifications/";

export const notifications = {
  async getNotifications() {
    try {
      const response = await Request.get(`${_api}${_notifications}`);

      return RequestHandler.processResponse(response);
    } catch (error) {
      return RequestHandler.processResponse(error);
    }
  },

  async putClearNotification(payload) {
    try {
      const response = await Request.put(`${_api}${_notifications}${payload.nNotificationID}/clear`);

      return RequestHandler.processResponse(response);
    } catch (error) {
      return RequestHandler.processResponse(error);
    }
  },

  async putClearAllNotifications() {
    try {
      const response = await Request.put(`${_api}${_notifications}clear-all`);

      return RequestHandler.processResponse(response);
    } catch (error) {
      return RequestHandler.processResponse(error);
    }
  }
}
