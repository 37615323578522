const UrlHelper = {
  getObjQueryParams(search) {
    let receivedVars = search ? search.replace('?', '') : '';
    receivedVars = receivedVars.substr(0, receivedVars.length).split("&");
    const objVars = {};
    receivedVars.forEach((item) => {
      const arrItem = item.split("=");
      objVars[arrItem[0]] = arrItem[1];
    });
    return objVars;
  },

  mountQueryString(objParams) {
    let finalString = "?";

    Object.keys(objParams).forEach((item, index, arrParams) => {
      finalString += `${item}=${encodeURIComponent(objParams[item])}`;
      finalString += index < arrParams.length - 1 ? "&" : "";
    });

    return finalString;
  },

  getUrlQueryParams() {
    const objVars = {};
    let receivedVars = window.location.hash !== '' && window.location.hash !== '#/'
      ? window.location.hash.replace('#/', '')
      : window.location.search.replace('/?', '');

    receivedVars = receivedVars.substr(1, receivedVars.length - 1).split('&');

    receivedVars.forEach((item) => {
      const arrItem = item.split('=');
      objVars[arrItem[0]] = arrItem[1];
    });

    return objVars;
  },
};

export default UrlHelper;
