import JsConfig from '../../src/js-config';
import CryptographyHelper from '../helpers/CryptographyHelper';
import NLLoggerEncoder from './NLLoggerEncoder';
import { db } from '../modules/db';
import jsConfig from '../../src/js-config';
import JSZip from 'jszip';

const types = Object.freeze({
  INFO: 'INFO',
  ERROR: 'ERROR',
});

const TIME_TO_RESEND_LOG = 60000;
export default class NLLogger {
  static enum = types;

  static doLogRequest(url, data) {
    return NLLogger.doLog(types.INFO, 'REQUEST', {
      url,
      data,
    });
  }

  static doLogResponse(hasError, url, data) {
    return NLLogger.doLog((hasError ? types.ERROR : types.INFO), 'RESPONSE', {
      url,
      data,
    });
  }

  static doInfoLog(message, data) {
    return NLLogger.doLog(types.INFO, message, data);
  }

  static doErrorLog(message, data) {
    return NLLogger.doLog(types.ERROR, message, data);
  }

  static doLog(status, message, data) {
    if (!Object.values(types).includes(status)) {
      throw new Error(`status ${status} not allowed, see NLLogger.enum to show allowed statuses.`);
    }
    if (!JsConfig.MUST_LOG) return;

    const headers = {
      Accept: 'application/json',
      'Content-type': jsConfig.IS_NEW_LOG_API ? 'plain/text' : 'application/json',
    };
    const apiUrl = JsConfig.LOG_API;
    const body = {
      location: window.location.href,
      status,
      message,
      hardwareID: CryptographyHelper.getHardwareID(),
      location: window.location.href,
      data: NLLoggerEncoder.prepareData(data),
      hardwareID: CryptographyHelper.getHardwareID(),
      time: new Date(),
    };

    if (JsConfig.IS_NEW_LOG_API) {
      const cipher = NLLoggerEncoder.assimetricEncrypt(JSON.stringify(body));
      if (cipher) {
        fetch(apiUrl, { method: 'POST', headers, body: cipher })
          .then((response) => {
            if (!response.ok) {
              db.table(status).add({ data: cipher, isEncrypted: true });
              if (!window.timerResendIndexLog)
                window.timerResendIndexLog = setInterval(NLLogger.sendIndexLog, TIME_TO_RESEND_LOG);
            }
          })
          .catch(() => {
            db.table(status).add({ isEncrypted: true, data: cipher });
          });
      } else {
        db.table(status).add({ data: body, isEncrypted: false });
      }
    } else {
      fetch(apiUrl, { method: 'POST', headers, body: JSON.stringify(body) })
        .then((response) => {
          if (!response.ok) {
            db.table(status).add({ data: body, isEncrypted: false });
          }
        })
        .catch(() => {
          db.table(status).add({ data: body, isEncrypted: false });
        });
    }
  }
  static async sendIndexLog() {
    clearInterval(window.timerResendIndexLog);
    const info = await db.table('INFO').toCollection().toArray();
    const allDataINFO = info.map((data) => {
      if (data.isEncrypted) {
        return data.data;
      }
    });
    const error = await db.table('ERROR').toCollection().toArray();
    let allDataERROR = [];
    error.forEach((data) => {
      if (data.isEncrypted) {
        allDataERROR.push(data.data);
      }
    });
    const body = {
      ...allDataERROR,
      ...allDataINFO,
    };
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
    };
    fetch(JsConfig.LOG_API, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    }).then(async (response) => {
      if (response.ok) {
        db.table('INFO')
          .filter((data) => data.isEncrypted)
          .delete();
        db.table('ERROR')
          .filter((data) => data.isEncrypted)
          .delete();
      }
    });
  }
  static cleanIndexLog() {
    if (!NLLogger.isLogTableEmpty()) {
      db.table('INFO').clear();
      db.table('ERROR').clear();
    }
  }
  static isLogTableEmpty() {
    return db.table('INFO').count() === 0 && db.table('ERROR').count() === 0;
  }
  static mustLogRequest() {
    return JsConfig.MUST_LOG;
  }
  static async downloadLogs(projectName) {
    const zip = new JSZip();
    const infoLogs = await db.table('INFO').toArray();
    const errorLogs = await db.table('ERROR').toArray();

    zip.file('info.json', infoLogs.map(log => JSON.stringify(log)).join('\n'));
    zip.file('error.json', errorLogs.map(log => JSON.stringify(log)).join('\n'));

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = `${projectName}_logs.zip`;
        link.click();
      });
  }
}
