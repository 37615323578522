const inter = {
  title: "inter",
  isInterInvest: true,
  isWL: true,
  director: "Clóvis Rodrigues de Lima",
  name: "Inter Educa",

  var: {
    showInHeader: true,
    navIcon: true,
    navItem: true,
    directorSignature: {
      top: '-70px',
      left: '-45px',
      width: '200px',
    },
    homeTabletMaxWidth: '642px'
  },

  homeInformation: {
    cardOne: 'homeInformation.cardOne',
    cardTwo: 'homeInformation.cardTwo',
    cardThree: 'homeInformation.cardThree'
  },

  header: {
    blogLink: '',
    blogName: '',
    helpName: '',
    helpLink: '',
  },

  aboutUs: {
    preface: 'preface',
    title: 'titleInter',
    subtitle: 'subtitle',
  },

  knowTeam: {
    title: 'knowTeam.title',
    subtitle: 'knowTeam.subtitle'
  },

  imgs: {
    homeVideoBg: '/themes/intereduca/info_video_home.svg',
    homeVideoThumb: 'https://i.vimeocdn.com/video/1480646872-b7e5e57a93f58e1c67298a50a1c6019b76519feca505779f2896e5bfeff1124e-d?mw=1000&mh=563',
    homeVideo: 'https://player.vimeo.com/video/731934018?h=8df42a303a&amp;badge=0&amp;autoplay=1;autopause=0&amp;player_id=0&amp;app_id=58479',
    unavailableContent: null,
    unavailableContentMobile: null,
    register: null,
    defaultPerfil: "/themes/intereduca/default-perfil.png",
    directorSignature: '/themes/intereduca/director-signature.png',
    homeTablet: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/iPad_pro_mockup_inter.webp',
  },

  breakpoints: {
    xs: "376px",
    sm: "426px",
    md: "769px",
    mdx: "900px",
    lg: "1025px",
    xl: "1440px",
    xxl: "1921px",
  },

  box: {
    md: "780px",
    lg: "1366px",
  },

  font: {
    family: {
      site: "Sora",
      title: "Inter",
    },
    families: "Sora:ital,100,200,300,400,500,600,700,800,900",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    size: {
      xxxs: "10px",
      xxs: "12px",
      xs: "14px",
      sm: "16px",
      md: "20px",
      lg: "24px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    custom: {
      size: {
        custom01: "18px"
      }
    }
  },

  border: {
    radius: {
      none: "0px",
      sm: "4px",
      md: "8px",
      lg: "20px",
      rounded: "100px",
      circular: "50%",
    },
  },

  color: {
    brand: {
      pure: "#FF7A00",
      darkest: "#E7661E",
      light: "#FE8A39",
    },
    gradient: {
      aboutUs: "linear-gradient(85.03deg, #FF7A00 2.15%, #E7661E 95.3%)",
      banner: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 63.54%, #020c13 100%)',
      bannerMobile: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 48.44%, #020c13 100%)',
    },
    neutral: {
      low: {
        pure: "#000000",
        darkest: "#EFEEED",
        dark: "#EFEEED",
        medium: "#5F6167",
        light: "#162D3B",
        lightest: "#1C384A",
      },
      high: {
        pure: "#FFFFFF",
        lightest: "#F5F4F2",
        light: "#D4DADD",
        medium: "#99A5AC",
        dark: "#5F707B",
        darkest: "#45545C",
      },
    },
    feedback: {
      success: "#009e22",
      warning: "#FEE60B",
      danger: "#F75158",
      dangerLight: "#FF7379",
      dangerDark: "#AB1A31",
      message: "#99A5AC",
    },
    background: {
      body: "#FFFFFF",
      imageInfoSection: "#FFFFFF",
      loader: "#FFFFFF",
      unavailableContent: "#FFFFFF",
      infoCards: "#FFFFFF",
      card: "#EFEEED",
      checkout: "#EFEEED",
      courseCard: "#EFEEED",
      cardDivider: "#5F6167",
      input: "#FFFFFF",
      inputSearch: "#EFEEED",
      result: "#EFEEED",
      resultHover: "#D4DADD",
      tab: "#FFFFFF",
      subMenu: "#FFFFFF",
      modal: "#FFFFFF",
      footer: "#EFEEED",
      header: "#FFFFFF",
      headerItem: "#EFEEED",
      infoBar: "#EFEEED",
      knowTeam: "#FFFFFF",
      bottomBar: "#EFEEED",
      cardImage: "#FFFFFF",
      nav: "#FF7A00",
      textArea: "#EFEEED",
      homeIndicator: "#5F6167",
      menu: "#FFFFFF",
      progressBar: "#FFFFFF",
      textInputDisabled: "#FFFFFF",
      textInput: "#FFFFFF",
      banner: '#020c13',
      courseLP: '#EFEEED',
      parallaxCourseLP: '#FFFFFF',
      compareBlock: "#FFFFFF",
      subscriptionContent: "#FF7A00",
      inputSearchContainer: "#EFEEED",
      inputSearchShortcut: "#D4DADD",
      progressColor: "#FFFFFF",
      moduleIndexOpen: "#FFFFFF",
      buttonDark: "#EFEEED",
      buttonDarkHover: "#EFEEED",
    },
    mobile: {
      background: "#EFEEED",
      title: "#000000",
      subtitle: "#5F6167",
      header: "#FFFFFF",
      couseModuleHeader: "#162D3B",
      card: "#FFFFFF",
      collapsible: "#5F6167",
      homeButton: "#5F6167",
      message: "#5F6167",
      inputSearch: "#EFEEED",
    },
    // CUSTOM
    backgroundDegrade: "linear-gradient(to bottom, rgba(73, 73, 73, 0), #1f1f1f 110%)",
    transparent: "transparent",
    primary: "#FF7A00",
    title: "#000000",
    secondary: "#5F6167",
    subtitle: "#5F6167",
    moduleIndex: "#5F6167",
    subtitleCustom: "#a6b2ba",
    description: "#5F707B",
    price: "#5F6167",
    label: "#5F6167",
    text: "#5F6167",
    textCustom: "#5F6167",
    secondaryText: "#909ba2",
    home: "#000000",
    header: "#000000",
    textLight: "#5F6167",
    cardHeader: "#000000",
    cardPrice: "#5F6167",
    cardText: "#5F6167",
    cardTextCheckout: "#5F6167",
    stepNumber: "#FFFFFF",
    infoLineTitle: "#5F6167",
    border: "#EFEEED",
    tabBorder: "#EFEEED",
    navBorder: "#EFEEED",
    headerBorder: "#EFEEED",
    collapsibleBorder: 'transparent',
    textAreaBorder: "#EFEEED",
    menuBorder: "#000000",
    cardBorder: "#000000",
    textInputBorder: "#FF7A00",
    quiz: "#000000",
    quizDarkest: "#FF7A00",
    tabColor: "#FF7A00",
    navColor: "#FF7A00",
    tabBar: "#FF7A00",
    darkSpan: "#5F6167",
    loginTitle: "#000000",
    message: "#5F6167",
    messageCustom: "#a6b2ba",
    formTitle: "#5F6167",
    navLink: "#5F6167",
    courseCard: "#5F6167",
    errorModal: "#5F6167",
    span: "#FF7A00",
    stroke: "#FFFFFF",
    strokeLight: "#D4DADD",
    collapsible: "#5F6167",
    comment: "#5F6167",
    unavailableContent: "#5F6167",
    courseProgress: "#5F6167",
    cardInfoCalendar: "#5F6167",
    courseLP: "#000000",
    infoSection: "#000000",
    continueText: "#5F6167",
    filterOptions: "#5F6167",
    classIC: "#45545C",
    headerTable: "#a6b2ba",
    progressColor: "#FF7A00",
    progressText: "#000000",
    liveNow: "#FF7379",
    iconColor: "#5F6167",
    searchIconColor: "#5F6167",
    inputSearchShortcutColor: "#5F6167",
    inputColor: "#000000",
    placeHolderColor: "#5F6167",
    titleContainerText: "#5F6167",
    copyrightText: "#5F6167",
    iconColorMobile: "#000000",
    iconColorMobileCustom: "#FFFFFF",
    userHome: "#000000",
    nav: "#FF7A00",
    breadcrumbLabel:"#5F6167",
    buttonDarkIcon: "#D4DADD",
  },
};

export default inter;
