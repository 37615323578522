import { types } from "../actions/types";

const initialState = {
	voucher: null
};

const voucherReducer = (state = initialState, action) => {
	const { VOUCHER } = types;
  const { type, payload } = action;

  switch (type) {
    case VOUCHER.SET: {
      return {
        ...state,
        voucher: payload,
      };
    }
    default:
      return state;
  }
};

export default voucherReducer;
