export const types = {
  PHONE: "phone",
  CPFCNPJ: "cpfCnpj",
  CEP: "cep",
  CEP_INTERNATIONAL: "cepInternational",
  CREDITCARD: "creditCard",
  CARDVALIDITY: "cardValidity",
  CVV: "CVV",
  DATEOFBIRTH: "dateOfBirth",
  ADDRESS: "address"
};
