import { combineReducers  } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import appReducer from "./reducers/appReducer";
import authReducer from './reducers/auth';
import { userReducer, userAllDataReducer } from './reducers/user';
import cardReducer from './reducers/card';
import courseReducer from './reducers/course';
import coursesReducer from './reducers/courses';
import voucherReducer from './reducers/voucher';
import courseLpReducer from './reducers/courselp';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import loadingReducer from './reducers/loading';
import purchasesReducer from './reducers/purchases';
import notificationsReducer from './reducers/notifications';
import commentsReducer from './reducers/comments';
import locationReducer from './reducers/location';

const persistConfig = {
  key: 'root',
  storage: localStorage
};

export const store = () => {
  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
      app: appReducer,
      auth: authReducer,
      loading: loadingReducer,
      purchases: purchasesReducer,
      card: cardReducer,
      user: persistReducer({ key: "auth", storage: localStorage, stateReconciler: hardSet }, userReducer),
      userAllData: userAllDataReducer,
      course: courseReducer,
      courses: coursesReducer,
      courseLp: courseLpReducer,
      voucher: voucherReducer,
      notifications: notificationsReducer,
      comments: commentsReducer,
      location: locationReducer,
    })
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  })

  const persistor = persistStore(store)

  return { store, persistor }
}

export default store;
