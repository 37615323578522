import styled from 'styled-components';

export const FormGroup = styled.div`
  width: 100%;
  font-weight: 500;
  margin-bottom: 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 26px;
  }
`;

export const Label = styled.label`
  margin-bottom: 8px;
  display: inline-block;
  font-size: ${(props) => props.theme.font.size.xs};
  line-height: 1.25;
  font-size: 12px;

  ${(props) => {
    if (props.theme.isWL) {
      return `color: ${props.theme.color.neutral.low.medium}`;
    }
  }}
`;
export const Cep = styled.a`
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: ${(props) => props.theme.font.size.xs};
  color: ${(props) => props.theme.color.primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
export const Input = styled.input`
  width: 100%;
  color: ${(props) => props.inputColor};
  font-size: ${(props) => props.theme.font.size.xs};
  line-height: 24px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'disabled':
        return props.theme.color.background.textInputDisabled;
      default:
        return props.theme.isInterInvest && props.isLogin ? props.theme.color.neutral.low.dark : props.theme.color.background.textInput;
    }
  }};
  border-radius: ${(props) =>
    props.inputWithButton ? "4px 0px 0px 4px" : props.theme.border.radius.sm};
  border: ${(props) => {
    switch (props.status) {
      case 'success':
        return "1px solid " + props.theme.color.feedback.success;
      case 'error':
        return "1px solid " + props.theme.color.feedback.danger;
      case 'disabled':
        if(props.theme.isWL)
          return "1px solid " + props.theme.color.textInputBorder;
        else
          return "4px solid " + props.theme.color.textInputBorder;
      default:
        if(props.isInvestMe){
          return props.theme.title === 'b3_dark' ?
          "4px solid " + props.theme.color.neutral.low.pure : "4px solid " + props.theme.color.neutral.low.darkest}
        else
          return "4px solid " + props.theme.color.neutral.low.dark;
    }
  }};
  padding: ${(props) => {
    if (props.hasIcon) {
      return '0px 30px 0px 12px !important';
    } else {
      return '0px 12px 0px 12px !important';
    }
  }};
  ::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: 1;
  }
  font-weight: ${(props) => props.theme.font.weight.regular};
  height: 40px;
`;
export const ContainerIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
export const Loader = styled.div`
  border: 3.5px solid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border-color: ${(props) => props.theme.color.primary}
    ${(props) => props.theme.color.primary} transparent;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 10px;
  margin: auto;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Required = styled.span `
  margin-left: 3px;
  color: ${(props) => props.theme.color.primary};
`;

export const LabelDescription = styled.span`
  display: inline-block;
  /* position: relative; */

  width: 15px;
  height: 15px;
  background-color: ${(props) => props.theme.color.primary};
  margin-left: 6px;
  border-radius: 50%;

  text-align: center;
  color: ${(props) => props.theme.color.neutral.high.pure};

  small {
    font-family: monospace;
    vertical-align: middle;
  }

  p {
    display: none;
    position: absolute;
  }

  &:hover {
    p {
      display: block;
      width: 100%;
      /* max-width: 50vw; */
      height: auto;
      background-color: ${(props) => props.theme.color.neutral.low.darkest};
      color: ${(props) => props.theme.color.neutral.high.pure};
      border-radius: 4px;
      padding: 8px;
      font-size: ${(props) => props.theme.font.size.xs};
      line-height: 1.25;
      font-weight: ${(props) => props.theme.font.weight.regular};
      bottom: 150%;
      left: 0px;
    }
  }
`;

export const FeedbackStatus = styled.span `
  margin-top: 12px;
  color: ${(props) => {
    switch (props.status) {
      case 'success':
        return props.theme.color.feedback.success;
      case 'error':
        return props.theme.color.feedback.danger;
      default:
        return props.theme.color.neutral.low.dark;
    }
  }};
  font-size: ${(props) => props.theme.font.size.xs};
  text-align: left;
  display: flex;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

export const BrandCardImage = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100%;
  max-width: 35px;
`;
export const Password = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
