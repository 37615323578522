const tabFilterItens = [
  {
    text: 'Todos os resultados',
    id: 'all',
    active: true,
  },
  {
    text: 'Cursos',
    id: 'course',
    active: false,
  },
  {
    text: 'Playlists',
    id: 'playlist',
    active: false,
  },
];

export default tabFilterItens;
