
import { types } from "./types";
import { creditCards } from "../../helpers/CreditCardHelper";
export const formatValuePhone = (value) => {
  if (value && value.length > 0) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d{4})$/, "$1-$2");
  }
  return value;
};
export const formatExpiry = (value) => {
  return value
    .replace(/[^0-9]/g, '' )
    .replace(/^([2-9])$/g, '0$1')
    .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
    .replace(/^0{1,}/g, '0')
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');
}
export const formatCPF = (value) => {
  if (value && value.length > 0) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{3})(\d)/g, "$1.$2");
    value = value.replace(/(\d{3})(\d{3})/g, "$1.$2");
    value = value.replace(/(\d{3})(\d{2})$/g, "$1-$2");
  }
  return value;
};
export const formatCNPJ = (value) => {
  if (value && value.length > 0) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d{3})(\d)/g, "$1.$2.$3");
    value = value.replace(/(\d{3})(\d{4})/g, "$1/$2");
    value = value.replace(/(\d{4})(\d{2})$/g, "$1-$2");
  }
  return value;
};
export const formatCPFOrCnpj = (value) => {
  if (value && value.length < 14) {
    return formatCPF(value);
  }
  return formatCNPJ(value);
};
export const formatCep = (value, international = false) => {
  if (value && value.length > 0) {
    if (international) {
      value = value.replace(/[^0-9a-zA-Z:-\s]+/gi, "");
    } else {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})$/, "$1-$2");
    }
  }
  return value;
};
const masks = {
  phone: (e) => {
    e.currentTarget.maxLength = 14;
    let value = formatValuePhone(e.currentTarget.value);
    e.currentTarget.value = value;
    return e;
  },
  dateOfBirth: (e) => {
    e.target.maxLength = 10;
    let value = e.target.value;
    if (value && value.length > 0) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/g, "$1/$2");
      value = value.replace(/(\d{2})(\d{4})$/g, "$1/$2");
    }
    e.target.value = value;
    return e;
  },
  cep: (e, international = false) => {
    e.target.maxLength = 9;
    let value = e.target.value;
    e.target.value = formatCep(value, international);
    return e;
  },
  cpfCnpj: (e) => {
    e.currentTarget.maxLength = 14;
    e.currentTarget.value = formatCPF(e.currentTarget.value);
    return e;
  },
  cardValidity: (e) => {
    e.target.value = formatExpiry(e.target.value);
    return e;
  },
  creditCard: (e, brand) => {
    const brandToUpper = brand.toUpperCase();
    e.currentTarget.maxLength = 19;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
  
    if (brandToUpper === creditCards.AMEX) {
      value = value.replace(/^(\d{4})(\d{6})(\d{5})$/, "$1 $2 $3");
    } else if (brandToUpper === creditCards.DINERS) {
      value = value.replace(/^(\d{4})(\d{6})(\d{4})$/, "$1 $2 $3");
    } else {
      value = value.replace(/^(\d{4})(\d)/, "$1 $2");
      value = value.replace(/(\d{4})(\d{4})/, "$1 $2");
      value = value.replace(/(\d{4})(\d{4})/, "$1 $2");
      value = value.replace(/(\d{4})(\d{4})$/, "$1 $2");
    }
  
    e.currentTarget.value = value;
  
    return e;
  },
  cvv: (e) => {
    e.target.maxLength = 4;
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{4})$/, "$1");
    e.target.value = value;
    return e;
  },
  address: (e) => {
    let value = e.target.value;
    value = value.replace(/[^\w\s\u00C0-\u024F'-().]/gi, "");
    e.target.value = value;
    return e;
  }
};
export const generateMaskedText = (e, mask, brand) => {
  const maskType = Object.values(types).find((el) => el === mask);
  if (maskType !== undefined) {
    switch (maskType) {
      case types.PHONE:
        return masks.phone(e);
      case types.DATEOFBIRTH:
        return masks.dateOfBirth(e);
      case types.CPFCNPJ:
        return masks.cpfCnpj(e);
      case types.CEP:
        return masks.cep(e, false);
      case types.CEP_INTERNATIONAL:
        return masks.cep(e, true);
      case types.CREDITCARD:
        return masks.creditCard(e, brand);
      case types.CARDVALIDITY:
        return masks.cardValidity(e);
      case types.CVV:
        return masks.cvv(e);
      case types.ADDRESS:
        return masks.address(e);
      default:
        break;
    }
  }
}
export default masks;
