import React, { useContext } from 'react';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Container, NavItem, NavLinkInvestMe } from "./styles";
import { useTranslation } from "react-i18next";
import SvgIcon from '../../handlers/HandleSvg';
import navMenuItens from '../../data/navMenuItens';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPlaylist } from '../../store/actions/app';
import { product } from '../../styles/theme/themes';
import EnvHelper from '../../helpers/EnvHelper';
import { useResponsive } from '../../helpers/HooksHelper';

const Nav = () => {
  const { t, i18n: {language} } = useTranslation("translations");
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const { loading } = useSelector((state) => state.loading);
  const colorByProduct = product === "INVESTACADEMY" ? themeContext.color.neutral.high.pure : themeContext.color.primary;
  const currentRoute = location.pathname;
  const { isMobile } = useResponsive();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isPlaylist } = useSelector((state) => state.app);

  const getIconByName = (item) => {
    switch (item.name) {
      case "home":
        if (currentRoute.includes("home")) {
          return item.iconActive;
        }
        return item.icon;
      case "lives":
        if (currentRoute.includes("lives")) {
          return item.iconActive;
        }
        return item.icon;
      default:
        if (currentRoute.includes("courses")) {
          return item.iconActive;
        }
        return item.icon;
    }
  };
  const getColorByName = (item) => {

    switch (item.name) {
      case "home":
        if (currentRoute.includes("home")) {
          return themeContext.color.nav;
        }
        return themeContext.color.neutral.high.dark;
      case "lives":
        if (currentRoute.includes("lives")) {
          return themeContext.color.nav;
        }
        return themeContext.color.neutral.high.dark;

      default:
        if (currentRoute.includes("courses")) {
          return themeContext.color.nav;
        }
        return themeContext.color.neutral.high.dark;
    }
  }
  const getIconSize = (item) => {
    const currentRoute = location.pathname;
    if (currentRoute.includes("lives") && item.name === "lives") {
      return "26px"
    }
    return "24px"
  }
  const arrNavMenuItens = navMenuItens.map((item, i, array) => {
    return (
      <React.Fragment key={i}>
        {item.to !== "" ?
        (
          <NavItem qtdItem={array.length} product={product} color={colorByProduct}>
            <NavLink exact={item.name === "home"} activeClassName="active" to={(!loading || currentRoute.includes(item.to)) ? `${item.to}` : {}}>
              {(themeContext.var.navIcon) && (
                <SvgIcon
                  name={getIconByName(item)}
                  color={getColorByName(item)}
                  width={getIconSize(item)}
                  height={getIconSize(item)}
                  iconClass="nav-link"
                />
              )}
              <span>{t(`${item.text}`)}</span>
            </NavLink>
          </NavItem>
        ): EnvHelper.isInvestMe() ? (
          <NavItem onClick={() => openPlaylist()} product={product} color={colorByProduct}>
            <a>{t(`${item.text}`)}</a>
          </NavItem>
        ) : null}
      </React.Fragment>
    );
  })
  const navigateTo = (to) => {
    history.push(`/${language}/${to}`);
    dispatch(setIsPlaylist(false));
    document.getElementById('playlistIframe').style.display = 'none';
    document.getElementById('reactParent').style.display = 'block';
  }
  const openPlaylist = () => {
    const iframe = document.getElementById('playlistIframe');
    iframe.style.display = 'block';
    document.getElementById('reactParent').style.display = 'none';
    dispatch(setIsPlaylist(true));
  }
  const arrNavMenuItensInvestMe = navMenuItens.map((item, i) => {
    return (
      <NavLinkInvestMe key={i}>
        {item.to !== "" ? (
          <a className={pathname.includes(item.to) && !isPlaylist ? 'active' : ''} onClick={() => navigateTo((!loading || currentRoute.includes(item.to)) ? item.to : {})}>
            <span>{t(`${item.text}`)}</span>
          </a>
        ) : isMobile ? (
          <a className={isPlaylist ? 'active' : ''} onClick={() => openPlaylist()}>
            <span>{t(`${item.text}`)}</span>
          </a>
        ) : null}
      </NavLinkInvestMe>
    )
  })
  return (
    <Container className={EnvHelper.isInvestMe() && themeContext.title === 'b3_dark' && 'invest-me-nav'}>
      <nav>
        <ul>
          {EnvHelper.isInvestMe() && isMobile && themeContext.title === 'b3_dark' ? arrNavMenuItensInvestMe : arrNavMenuItens}
        </ul>
      </nav>
    </Container>
  );
};

export default Nav;
