import { types } from "../actions/types";

const initialState = {
    purchases: []
};

const purchasesReducer = (state = initialState, action) => {
  const { PURCHASES } = types;
  const { type, payload } = action;

  switch (type) {
    case PURCHASES.SET:
      return {
        ...state,
        purchases: payload
      };
      case PURCHASES.CANCEL: {
        const { purchases = [] } = state;
        const { id, newStatus } = payload;

        return {
          ...state,
          purchases: [
            ...purchases.map(item => {
              if (item.nVendaID == id) {
                return {
                  ...item,
                  sale: {
                    ...item.sale,
                    nStatus: newStatus
                  }
                }
              }
              else
                return item;
            })
          ]
        };
      }
    default:
      return state;
  }


};

export default purchasesReducer;

