import styled from 'styled-components';

export const Content = styled.div`
  ${(props) => {
    if (props.product === "INVESTMEB3") {
      return `color: ${props.theme.color.neutral.high.light}`;
    } else {
      return `color: ${props.theme.color.neutral.high.medium}`;
    }
  }}
`;

export const HeaderText = styled.div`
    font-weight: 500;
    font-size: 20px;
    max-width: 95%;
    text-align: center;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 500;
`;

export const Body = styled.div`
  margin-top: 26px;
  color: ${props => props.theme.color.neutral.high.medium};
  font-size: ${props => props.theme.font.size.xs};
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 44px;
    gap: 11px;
`;
