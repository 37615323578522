import { types } from "./types";

export const setIsLoggedIn = (newValue) => {
  const { LOGIN } = types;

  return {
    type: LOGIN.SUCCESS,
    payload: newValue,
  };
}

export const setLoggedOut = () => {
  const { LOGIN } = types;

  return {
    type: LOGIN.LOGOUT,
    payload: false
  }
}