import NLLogger from '@common/classes/nelogica/NLLogger';
import StorageHelper from "./StorageHelper";

const _canInsiderTrack = () => {
  return StorageHelper.getFromLocalStorage('envProduct') === 'INVESTACADEMY';
}

const _trackInsiderNavigation = (insiderType, insiderUser, insiderProduct, insiderBasket) => {
  window.insider_object = window.insider_object || {};
  window.insider_object.page = {
    type: insiderType,
  };

  if (insiderProduct) window.insider_object.product = insiderProduct;
  if (insiderUser) window.insider_object.user = insiderUser;
  if (insiderBasket) {
    window.insider_object.basket = insiderBasket;
    StorageHelper.setToLocalStorage('insider_basket', insiderBasket);
  }

  window.Insider?.eventManager.dispatch('init-manager:re-initialize');
};

const _trackInsiderConfirmation = (transactionObj) => {
  window.insider_object = window.insider_object || {};
  window.insider_object.page = window.insider_object.page || {};

  if (transactionObj) window.insider_object.transaction = transactionObj;

  if (window.insider_object.page.type === 'Confirmation') return;

  window.insider_object.page = {
    type: 'Confirmation',
  };
  StorageHelper.removeFromLocalStorage('insider_basket');

  window.Insider.eventManager.dispatch('init-manager:re-initialize');
};

const _trackInsiderEvent = (eventInfos) => {
  window.Insider.track('events', [eventInfos]);
};

const _deleteInsiderParams = () => {
  if (window.insider_object) {
    delete window.insider_object.product;
    delete window.insider_object.transaction;
  }
};

export const UserTrackingHelper= {
  trackNavigation(insiderType = 'Other', insiderUser = null, product = null) {
    _deleteInsiderParams();

    if (_canInsiderTrack()) {
      try {
        const insiderBasket = product && insiderType === 'Checkout'
          ? UserTrackingHelper.createInsiderBasket(product)
          : StorageHelper.getFromLocalStorage('insider_basket');

        _trackInsiderNavigation(insiderType, insiderUser, UserTrackingHelper.createInsiderProduct(product), insiderBasket);
      } catch (error) {
        console.error(error);
        NLLogger.doLog('ERROR', 'INSIDER.TRACK - Error to track user navigation', { error: String(error) });
      }
    }
  },

  trackPurchase(product = null) {
    _deleteInsiderParams();

    if (_canInsiderTrack()) {
      try {
        _trackInsiderConfirmation(UserTrackingHelper.createInsiderTransaction(product));
      } catch (error) {
        console.error(error);
        NLLogger.doLog('ERROR', 'INSIDER.TRACK - Error to track user confirmation', { error: String(error) });
      }
    }
  },

  trackEvent(eventInfos) {
    _deleteInsiderParams();

    if (_canInsiderTrack()) {
      try {
        _trackInsiderEvent(UserTrackingHelper.createInsiderEvent(eventInfos));
      } catch (error) {
        console.error(error);
        NLLogger.doLog('ERROR', 'INSIDER.TRACK - Error to track event', { error: String(error) });
      }
    }
  },

  getInsiderType(route) {
    if(route === '' || route === '/' || route === '/home') return 'Home';
    else if (route.includes('all-courses')) return 'Category';
    else if (route.includes('courselp')) return 'Product';
    else if (route.includes('checkout/sign-in') || route.includes('checkout/sign-up')) return 'Checkout';

    return 'Other';
  },

  mustSkipTrackRoute(route) {
    return route.includes('courselp') || route.includes('checkout/sign-in') || route.includes('checkout/sign-up');
  },
  createInsiderProduct(product) {
    if (!product) return;

    const objProduct = {
      id: product.id || String(product.course.nCourseID) || product.name?.toLowerCase().replace(' ', '-'),
      name: product.course?.strName,
      taxonomy: [product.type, product.course?.strName],
      currency: product.currency,
      unit_price: parseFloat(product['subscription-plan']?.fValorAVista) || parseFloat(product.plan?.fValorAVista) || 0,
      unit_sale_price: parseFloat(product.total) || parseFloat(product['subscription-plan']?.fValorAVista) || parseFloat(product.plan?.fValorAVista) || 0,
      url: `${window.location.origin}/courselp/${product.nClassID}`,
    };
    const modalities = {
      1: 'mensal',
      6: 'semestral',
      12: 'anual',
    };
    // CUSTOM
    const customFields = [
      ['product_id', objProduct.id],
      ['modality', product.modality?.toLowerCase() || modalities[product.nLoyaltyDeadline] || 'mensal'],
      ['slug', product.strSlug],
      ['trialDays', product.trialDays],
      ['product_type', 'Educacional'],
      ['category', 'Cursos'],
      ['license_type', product.license_type],
      ['broker', product.broker],
    ];

    customFields.forEach(([key, value]) => {
      if (value) {
        objProduct.custom = { ...objProduct.custom, [key]: value };
      }
    });

    return objProduct;
  },
  createInsiderTransaction(product) {
    const objProduct = UserTrackingHelper.createInsiderProduct(product);
    objProduct.custom.event_group_id = product.orderID || objProduct.id;
    const insiderObject = {
      order_id: product.orderID || objProduct.id,
      currency: objProduct.currency,
      total: objProduct.unit_sale_price,
      line_items: [
        {
          product: objProduct,
          quantity: 1,
          subtotal: objProduct.unit_sale_price,
        },
      ],
    };
    if (product.paymentType) insiderObject.payment_type = product.paymentType;
    if (product.course.strBanner) insiderObject.line_items[0].product.product_image_url = product.course.strBanner;
    return insiderObject;
  },
  createInsiderEvent(eventObject) {
    const objProduct = UserTrackingHelper.createInsiderProduct(eventObject);

    const insiderEvents = {
      event_name: eventObject.eventName,
      timestamp: new Date(),
      event_params: {
        product_id: objProduct.id,
        product_name: objProduct.name,
        taxonomy: objProduct.taxonomy,
        currency: objProduct.currency,
        broker_name: '',
        unit_price: objProduct.unit_price,
        unit_sale_price: objProduct.unit_sale_price,
        custom: {
          ...objProduct.custom,
          'checkout-step': eventObject.checkoutStep,
        },
      },
    };
    if (eventObject.error) insiderEvents.event_params.custom.error = eventObject.error;
    if (eventObject.httpCode) insiderEvents.event_params.custom.http_code = eventObject.httpCode;
    if (eventObject.cieloStripeCode) insiderEvents.event_params.custom.cielo_stripe_code = eventObject.cieloStripeCode;
    if (eventObject.slug) insiderEvents.event_params.custom.slug = eventObject.slug;

    return insiderEvents;
  },

  createInsiderBasket(product) {
    const objProduct = UserTrackingHelper.createInsiderProduct(product);

    const insiderObject = {
      currency: objProduct.currency,
      total: objProduct.unit_sale_price,
      line_items: [
        {
          product: objProduct,
          quantity: 1,
          subtotal: objProduct.unit_sale_price,
        },
      ],
    };

    if (product.image || product.strBannerUrl) insiderObject.line_items[0].product.product_image_url = product.image || product.strBannerUrl;

    return insiderObject;
  }
};

export default UserTrackingHelper;
