import React, { useContext } from 'react';
import { Container } from './styles';
import Nav from '../Nav';
import { product } from '../../styles/theme/themes';
import EnvHelper from '../../helpers/EnvHelper';
import { ThemeContext } from 'styled-components';

const SubMenu = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <Container id="subMenu"
      product={product} className={EnvHelper.isInvestMe() && themeContext.title === 'b3_dark' && 'invest-me-submenu'}>
      <Nav/>
    </Container>
  )
}

export default SubMenu;
