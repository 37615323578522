import { types } from "../actions/types";

const initialState = {
  card: {
    expirationDate: null,
    fullExpirationDate: null,
    holderName: null,
    lastNumbers: null,
    nCartaoCreditoID: null,
    paymentNetwork: null,
    icon: null
  }
}

export const cardReducer = (state = initialState, action) => {
  const { CARD } = types
  const { type, payload } = action

  switch (type) {
    case CARD.SUCCESS:
      return {
        ...state,
        card: payload,
      }
    default:
      return state;
  }
}

export default cardReducer;