class JsConfig {
  constructor() {
    this.FLAVOR = null;
    this.WEB_API = null;
    this.WEB_SOCKET = null;
    this.CAPTCHA_KEY = null;
    this.CAPTCHA_V3 = null;
    this.MUST_LOG = null;
    this.LOG_API = null;
    this.LOG_ENCODE_KEY = null;
    this.PROJECT = null;
    this.IS_NEW_LOG_API = null;
  }
}

export default new JsConfig();
