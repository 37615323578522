import { types } from "../actions/types";
import CountryHelper from "@common/classes/helpers/CountryHelper";

const initialState = {
  location: {
    country: '',
    countryId: '',
    ip: '',
    region: '',
  },
  isLocationBR: true,
  isLocationLoaded: false,
};

const locationReducer = (state = initialState, action) => {
  const { LOCATION } = types;
  const { type, payload } = action;

  switch (type) {
    case LOCATION.SUCCESS:
      return {
        ...state,
        location: payload,
        isLocationBR: payload.nCountryId === CountryHelper.BRAZIL_ID,
        isLocationLoaded: true,
      };
    default:
      return state;
  }
};

export default locationReducer;
