import styled from 'styled-components';
import { css } from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: ${(props) => props.hideHeader ? '0' : '64px'};
  z-index: 1040;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    top: ${(props) => props.hideHeader ? '0' : '52px'};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    top: 0;
  }
`;

export const ModalWrapper = styled.div`
  ${(props) => {
    if (props.center) {
      return `
            display: flex;
            align-items: center;
            `;
    }

    return `
        position: fixed;
        top: 50px;
        left: 0;
        `;
  }}
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    ${(props) => {
      if (props.center) {
        return `
            display: flex;
            align-items: center;
            `;
      }

      return `
        position: fixed;
        top: 0px;
        left: 0;
        overflow: hidden;
        `;
    }}
  }
  &.terms {
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      top: 0;
    }
  }

  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  &.error-modal {
    padding: 0 20px;
  }
`;

export const DivModal = styled.div`
  z-index: 100;
  background: ${(props) => props.theme.color.background.modal};
  position: relative;
  margin: 20px auto;
  max-width: ${(props) => (props.width ? props.width : "512px")};
  padding: 16px 16px;
  border-radius: 10px;

  ${(props) => props.fullScreenMobile && FullScreenMobile};

  &.terms {
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      margin: 0 auto;
      max-width: 100%;
      min-height: 100%;
      padding: 16px 0 0 0;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      min-height: 0;
      height: calc(100% - 20px);
    }
  }

  &.error-modal {
    padding: 24px;
  }

  &.modal-signup {
    min-height: unset;

    background-color: ${(props) => props.theme.color.background.card};

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      max-height: calc(100vh - 170px);
      border-radius: 8px;
    }
  }

  &.modal-signup-success {
    padding: 0%;
  }

  &.teacher-modal {
    padding: 16px 16px 0 0;
  }
`;

export const FullScreenMobile = css`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 100%;
    border-radius: 0px;
    margin: initial;
  }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 8px 8px 8px;
    font-weight: bold;

    &.terms {

        h2 {
            ${(props) => {
              if (props.theme.isWL) {
                return `color: ${props.theme.color.neutral.low.pure}`;
              }
            }};
            font-size: 18px;
            font-weight: ${(props) => props.theme.font.weight.medium};
            margin-left: 14px;
            &.new-contract, &.contract {
              margin-left: 0;
            }
        }
        justify-content: space-between;
        min-height: 58px;
        border-bottom: 1px solid ${(props) => props.theme.color.neutral.low.light};

        @media (max-width: ${(props) => props.theme.breakpoints.md}) {
            padding: 0 16px 8px 16px;
            gap: 15px;
            justify-content: flex-end;
        }
    }

    &.error-modal {
      padding: 0px 8px 8px 0px;
    }
`;

export const ModalBody = styled.div`
    padding: 8px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      max-height: calc(100% - 80px);
      position: relative;
      overflow-y: auto;

      &&::-webkit-scrollbar {
        display: none;
      }
    }

    &.terms {
      overflow-y: auto;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        height: 100vh;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 8px 24px 16px;
      }
    }

    &.modal-signup {
      overflow-y: auto;
      max-height: calc(100vh - 240px);

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        height: 100vh;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 8px 24px 16px;
      }
    }

    &.error-modal {
      padding: 16px 0 0;
      min-width: 220px;
    }

    &.modal-signup-success {
      padding: 0;
    }

    &.teacher-modal {
      padding: 0;
      min-height: 270px;
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 8px;

    &.error-modal {
      padding: 0;
    }
`;

export const ModalError = styled.div`
    display:flex;
    background-color: ${(props) => props.errorColor};
    position: absolute;
    width: 100%;
    height: 40px;
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.regular};
    color: ${(props) => props.theme.color.neutral.low.pure};
    font-size: ${(props) => props.theme.font.size.xxs};
    top: 0;
    left: 0;
    border-radius: 10px 10px 0px 0px;
    justify-content: center;
    z-index: 10;
    p {
        margin-left: 4px;
        font-size: ${(props) => props.theme.font.size.xs};
    }
    Button {
        position: absolute;
        right: 0;
        margin-right: 15.5px;
    }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`
