import { types } from '../actions/types';

const initialState = {
  nThemeID: null,
  nModuleID: null,
  bVisible: null,
  nClassID: null,
  lastPage: null,
  active_comments: null,
};

const commentsReducer = (state = initialState, action) => {
	const { COMMENTS } = types;
  const { type, payload } = action;

  switch (type) {
    case COMMENTS.SET_COMMENTS: {
      return {
        ...state,
        ...payload
      };
    }
    case COMMENTS.ADD_NEW_COMMENT: {
      const { newComment = {} } = payload;
      const { active_comments } = state;

      return {
        ...state,
        active_comments: [newComment, ...active_comments],
      };
    }
    case COMMENTS.ADD_NEW_REPLY: {
      const { newReply = {}, parentId } = payload;
      const { active_comments } = state;
      return {
        ...state,
        active_comments: [
          ...active_comments.map(item => {
            if (item.nCommentID === parentId) {
              const {active_replies = []} = item;
              return {
                ...item,
                active_replies: [...active_replies, newReply],
              };
            } else
              return item;
          })
        ],
      }
    }
    case COMMENTS.UPDATE_COMMENT: {
      const { active_comments = [] } = state;
      const { id, updatedComment } = payload;

      return {
        ...state,
        active_comments: [
          ...active_comments.map(item => {
            if (item.nCommentID == id) {
              return {
                ...item,
                strComment: updatedComment
              };
            } else
              return item;
          })
        ],
      };
    }
    case COMMENTS.UPDATE_REPLY: {
      const { active_comments = [] } = state;
      const { parentId, id, updatedReply } = payload;

      return {
      ...state,
      active_comments: [
        ...active_comments.map(item => {
          if (item.nCommentID == parentId) {
            const {active_replies = []} = item;

            return {
              ...item,
              active_replies: [...active_replies.map(reply => {
                  if (reply.nCommentReplyID == id) {
                    return {
                      ...reply,
                      strReply: updatedReply
                    }
                  } else
                    return reply;
                })
              ],
            }
          }
          else
            return item;
        })
      ]
    };
    }
    case COMMENTS.DELETE_COMMENT: {
      const { active_comments = [] } = state;
      const { id } = payload;

      return {
        ...state,
        active_comments: [
          ...active_comments.filter(comment => comment.nCommentID !== id)
        ],
      };
    }
    case COMMENTS.DELETE_REPLY: {
      const { active_comments = [] } = state;
      const { parentId, id } = payload;

      return {
        ...state,
        active_comments: [
          ...active_comments.map(item => {
            if (item.nCommentID == parentId) {
              const {active_replies = []} = item;
              return {
                ...item,
                active_replies: active_replies.filter(comment => comment.nCommentReplyID !== id),
              };
            } else
              return item;
          })
        ],
      };
    }
    case COMMENTS.ADD_NEW_PAGE: {
      const { newPageComments = [], lastPage = false } = payload;
      const { active_comments } = state;

      return {
        ...state,
        active_comments: [...active_comments, ...newPageComments],
        lastPage,
      };
    }
    default:
      return state;
  }
};

export default commentsReducer;
