import i18n from 'i18next';
import backend from 'i18next-http-backend';
import languagedetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationsPT from '../locales/pt/translations.json';
import translationsEN from '../locales/en/translations.json';
import translationsES from '../locales/es/translations.json';
const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
const defaultLanguage = navigator.language.split('-')[0];
export const supportedLngs = ["pt","en","es"];

i18n
  .use(backend)
  .use(languagedetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translations: translationsPT,
      },
      en: {
        translations: translationsEN,
      },
      es: {
        translations: translationsES,
      },
    },
    fallbackLng: defaultLanguage,
    debug: isDev,
    detection: {
      order: ["path", "queryString", "cookie"],
      cache: ["cookie"],
    },
    supportedLngs,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
