import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  transition: all 0.5s;

  &.course-check {
    position: relative;
  }
  mask {
    #mask1_1007_12633,
    #mask0_1007_12633 {
      mask-type: alpha;
    }
  }
`;

export const IcPlay = styled.div`
  background-color: ${(props) => props.theme.color.neutral.high.light};
  border-radius: ${(props) => props.theme.border.radius.circular};
  padding: 15px;
`;
