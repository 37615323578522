import { types } from "../actions/types";

const initialState = {
    loading: false,
    partialPageLoading: false
};

const loadingReducer = (state = initialState, action) => {
  const { LOADING, PARTIALPAGELOADING } = types;
  const { type, payload } = action;
  switch (type) {
    case LOADING.SET:
      return {
        ...state,
        loading : payload
      };
    case PARTIALPAGELOADING.SET:
      return {
        ...state,
        partialPageLoading : payload
      };
    default:
      return state;
  }


};

export default loadingReducer;

