import StorageHelper from './StorageHelper';
import CryptoJS from 'crypto-js';

export default class CryptographyHelper {
  static getHardwareID = () => {
    let hardwareID = StorageHelper.getLocal('hardwareID');

    if (!hardwareID) {
      hardwareID = this.generateRandomHash(16);
      StorageHelper.setLocal('hardwareID', hardwareID);
    }

    return hardwareID.toString();
  }

  static generateRandomHash = (length = 16) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    let hexString = '';
    for (const value of array) {
      hexString += value.toString(16).padStart(2, '0');
    }
    return hexString;
  }

  static getCryptographedSHA1 = async (word) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(word);

    let hashHex;
    await window.crypto.subtle.digest("SHA-1", dataBuffer).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    });
    return hashHex;
  }

  static getCryptographedMD5 = async (inputText) => {
    return CryptoJS.MD5(inputText).toString(CryptoJS.enc.Hex);
  }
}
