import StorageHelper from "./StorageHelper";
import EnvHelper from "./EnvHelper";
import UrlHelper from "./UrlHelper";

const UserHelper = {
  isLogged() {
    const accessToken = this.getBearerToken();
    return !(accessToken === "" || !accessToken);
  },

  getLoggedUser() {
    const user = StorageHelper.getFromLocalStorage("persist:auth");
    return user;
  },

  getBearerToken() {
    const queryParams = UrlHelper.getUrlQueryParams();
    const token = EnvHelper.isPeer() ? StorageHelper.getFromLocalStorage('access_token') : queryParams.token || StorageHelper.getFromLocalStorage('access_token');
    const timestamp_token = StorageHelper.getFromLocalStorage('timestamp_token');

    if (token) {
      if (!EnvHelper.isInvestMe() && Date.now() > timestamp_token) {
        this.removeBearerToken();
      }
      return token;
    }
    return "";
  },

  removeBearerToken() {
    StorageHelper.removeFromLocalStorage('access_token');
    StorageHelper.removeFromLocalStorage('timestamp_token');
    StorageHelper.removeFromLocalStorage('expires_in');
    StorageHelper.removeFromLocalStorage('persist:root');
    StorageHelper.removeFromLocalStorage('persist:auth');
  },

  getHardwareID() {
    let hardwareID = StorageHelper.getFromLocalStorage('hardwareID');
    if (!hardwareID) {
      // eslint-disable-next-line no-undef
      const array = new Uint8Array(16);
      window.crypto.getRandomValues(array);
      let hexString = '';
      for (const value of array) {
        hexString += value.toString(16).padStart(2, '0');
      }
      hardwareID = hexString;
      window.localStorage.setItem('hardwareID', hardwareID);
    }

    return hardwareID;
  },

  checkUserData(data) {
    return Object.entries(data)
      .map((el) => {
        switch (el[0]) {
          case "email":
            return el[1] && el[1].length > 0;
          case "firstName":
            return el[1] && el[1].length > 0;
          case "lastName":
            return el[1] && el[1].length > 0;
          case "document":
            return el[1] && el[1].length > 0;
          case "dateOfBirth":
            return el[1] && el[1].length > 0;
          case "postCode":
            return el[1] && el[1].length > 0;
          case "publicPlace":
            return el[1] && el[1].length > 0;
          case "number":
            return el[1] && el[1].length > 0;
          case "neighborhood":
            return el[1] && el[1].length > 0;
          case "state":
            return el[1] && el[1].length > 0;
          case "city":
            return el[1] && el[1].length > 0;
        }
      })
      .filter((el) => el !== undefined)
      .every((el) => el !== null);
  },

  normalizeUserObject(object) {
    const dateOfBirth = object.dateOfBirth ? object.dateOfBirth.replace(/^(\d{2}\/)(\d{2})(\d{4})$/g, "$1$2/$3").replace("00:00:00", '').trim() : "";
    const newObj = {
      username: object.firstName || "",
      usersurname: object.lastName || "",
      email: object.email || "",
      cellphone: object.cellPhone || "",
      document: object.document || "",
      dateOfBirth,
      cep: object.postCode || "",
      address: object.publicPlace || "",
      number: object.number || "",
      complement: object.addressComplement || "",
      neighborhood: object.neighborhood || "",
      city: object.city || "",
      state: object.state || "",
      nAddressCountry: `${object.nAddressCountry}` || "",
    };
    return newObj;
  }

};

export default UserHelper;
